/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF, Float } from '@react-three/drei'
import gsap from 'gsap'

function ProjectModel2(props) {
  const { nodes, materials } = useGLTF('./assets/maillot.glb')
  const groupRef = useRef()

  return (
    <Float>
      <group 
        {...props} 
        ref={groupRef}
        dispose={null}
        position={[0, -0.7, 0]}
        onPointerOver={(e) => {
          e.stopPropagation()
          document.body.style.cursor = 'pointer'
          gsap.to(groupRef.current.rotation, {
            y: '+=0.3',
            duration: 0.3,
            yoyo: true,
            repeat: 1,
            ease: 'power1.inOut'
          })
        }}
        onPointerOut={(e) => {
          e.stopPropagation()
          document.body.style.cursor = 'default'
          gsap.to(groupRef.current.rotation, {
            y: 0,
            duration: 0.3,
            ease: 'power1.inOut'
          })
        }}
      >
        <group scale={0.2}>
          <primitive 
            object={nodes.Scene}
            rotation={[0, -Math.PI / 2, 0]}
          />
        </group>
      </group>
    </Float>
  )
}

useGLTF.preload('./assets/maillot.glb')

export default ProjectModel2